import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn } from 'ahooks';

const DReportDetail = React.forwardRef(({ expandedSupplier }, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	const lineRender = useMemoizedFn((val) => {
		if (val) {
			return (
				<Fragment>
					{val}
					<br />
				</Fragment>
			);
		} else {
			return <Fragment />;
		}
	});

	return (
		<div ref={ref}>
			<ul className={expandedSupplier ? 'lstSupplier' : 'lstSupplier hidden'}>
				<li>
					<span>Notification No</span>
					<p>8000002592713</p>
				</li>
				<li>
					<span>Notification Date</span>
					<p>01-05-2021</p>
				</li>
				<li>
					<span style={{ color: '#06ae56' }}>Purchase Order</span>
					<p>54999463</p>
				</li>
				<li>
					<span>Complaint Quantity</span>
					<p>39683.207 LB</p>
				</li>
				<li>
					<span style={{ color: '#06ae56' }}>Reference Quantity</span>
					<p>567.207 LB</p>
				</li>
				<li>
					<span>BASF Material No</span>
					<p>54999463</p>
				</li>
				<li>
					<span>BASF Material Name</span>
					<p>PARA-CHLORPHENYLGLYCINE</p>
				</li>
				<li>
					<span>Name</span>
					<p>Yuen Yee Ng</p>
				</li>
				<li>
					<span>Dept/Site</span>
					<p>GDD/EC</p>
				</li>
				<li>
					<span>Telephone</span>
					<p>+8522731 1520</p>
				</li>
				<li>
					<span>Fax</span>
					<p>+8522731 5638</p>
				</li>
				<li>
					<span style={{ color: '#06ae56' }}>Ship To:</span>
					<p className="lh22">
						{lineRender('recipient')}
						{lineRender('street')}
						{lineRender('postcode1')}
						{lineRender('city1')}
						{lineRender('country')}
					</p>
				</li>
				<li>
					<span>{t('Detail.InternalReferenceNumber')}*</span>
					<input type="text" id="txb_int_ref_number" className="txbSupplier" />
				</li>
				<li>
					<span>{t('Detail.InternalInformation')}*</span>
					<textarea id="txa_int_info" className="txaSupplier" />
				</li>
			</ul>
			<ul
				className={
					expandedSupplier ? 'lstSupplier alt' : 'lstSupplier alt hidden'
				}
			></ul>
			<p className={expandedSupplier ? 'disclaimer' : 'disclaimer hidden'}>
				* {t('Detail.informationfeedback')}
			</p>
		</div>
	);
});

export default memo(DReportDetail);
