import React, { useState, useEffect, useImperativeHandle } from 'react';
import '../index.scss';
import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import { Collapse, Form } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/button/style/index.css';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import '../../../../node_modules/antd/lib/collapse/style/index.css';
// import D13Info from './D13Info';
import D4D5 from './D4D5';
import D6Verification from './D6Verification';
import D7Prevention from './D7Prevention';
import D8Communicate from './D8Communicate';
import { useMount, useMemoizedFn, useUpdateEffect, useReactive } from 'ahooks';
import _ from 'lodash';
import { CheckCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;

const Tab2 = React.forwardRef(({ form }, ref) => {
	const [startDateData, setStartDateData] = useState(null);
	const [endDateData, setEndDateData] = useState(null);

	const d4d5_rac_value = Form.useWatch('d4d5_rac', form);
	const d4d5_rcaother_value = Form.useWatch('d4d5_rcaother', form);
	const d4d5_value = Form.useWatch('d4d5', form);

	const d6_verification_value = Form.useWatch('d6_verification', form);
	const d6_responsible_value = Form.useWatch('d6_responsible', form);
	const d6_datedue_value = Form.useWatch('d6_datedue', form);
	const d6_datecompl_value = Form.useWatch('d6_datecompl', form);

	const d7_prevention_value = Form.useWatch('d7_prevention', form);
	const d7_responsible_value = Form.useWatch('d7_responsible', form);
	const d7_datedue_value = Form.useWatch('d7_datedue', form);
	const d7_datecompl_value = Form.useWatch('d7_datecompl', form);
	const d7_choice_value = Form.useWatch('d7_choice', form);

	const d8_communicate_value = Form.useWatch('d8_communicate', form);
	const d8_prepared_value = Form.useWatch('d8_prepared', form);
	const d8_datecompl_value = Form.useWatch('d8_datecompl', form);

	useMount(() => {
		//
	});

	useImperativeHandle(ref, () => ({
		D45error: () => {
			renderData.activeKey.push('45');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
		D6error: () => {
			renderData.activeKey.push('6');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
		D7error: () => {
			renderData.activeKey.push('7');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
		D8error: () => {
			renderData.activeKey.push('8');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
	}));

	useUpdateEffect(() => {
		if (d4d5_rac_value && d4d5_rac_value === 'other' && d4d5_rcaother_value) {
			let temp = [];
			for (let i = 0; i < d4d5_value.length; i++) {
				let obj = {};
				obj.define = d4d5_value[i].define;
				for (let j = 0; j < d4d5_value[i].d5.length; j++) {
					obj.permanent = d4d5_value[i].d5[j].permanent;
					obj.responsible = d4d5_value[i].d5[j].responsible;
					obj.datedue = d4d5_value[i].d5[j].datedue;
					obj.datecompl = d4d5_value[i].d5[j].datecompl;
				}
				temp.push(obj);
			}
			for (let i = 0; i < temp.length; i++) {
				let flag = _.values(temp[i]).some((v) => v === '' || !v);
				if (!flag) {
					renderData.D45done = true;
				} else {
					renderData.D45done = false;
				}
			}
		} else if (d4d5_rac_value && d4d5_rac_value !== 'other') {
			let temp = [];
			for (let i = 0; i < d4d5_value.length; i++) {
				let obj = {};
				obj.define = d4d5_value[i].define;
				for (let j = 0; j < d4d5_value[i].d5.length; j++) {
					obj.permanent = d4d5_value[i].d5[j].permanent;
					obj.responsible = d4d5_value[i].d5[j].responsible;
					obj.datedue = d4d5_value[i].d5[j].datedue;
					obj.datecompl = d4d5_value[i].d5[j].datecompl;
				}
				temp.push(obj);
			}
			for (let i = 0; i < temp.length; i++) {
				let flag = _.values(temp[i]).some((v) => v === '' || !v);
				if (!flag) {
					renderData.D45done = true;
				} else {
					renderData.D45done = false;
				}
			}
		} else {
			renderData.D45done = false;
		}
	}, [d4d5_rac_value, d4d5_rcaother_value, d4d5_value]);

	useUpdateEffect(() => {
		if (
			d6_verification_value &&
			d6_responsible_value &&
			d6_datedue_value &&
			d6_datecompl_value
		) {
			renderData.D6done = true;
		} else {
			renderData.D6done = false;
		}
	}, [
		d6_verification_value,
		d6_responsible_value,
		d6_datedue_value,
		d6_datecompl_value,
	]);

	useUpdateEffect(() => {
		if (
			d7_prevention_value &&
			d7_responsible_value &&
			d7_datedue_value &&
			d7_datecompl_value &&
			d7_choice_value
		) {
			renderData.D7done = true;
		} else {
			renderData.D7done = false;
		}
	}, [
		d7_prevention_value,
		d7_responsible_value,
		d7_datedue_value,
		d7_datecompl_value,
		d7_choice_value,
	]);

	useUpdateEffect(() => {
		if (d8_communicate_value && d8_prepared_value && d8_datecompl_value) {
			renderData.D8done = true;
		} else {
			renderData.D8done = false;
		}
	}, [d8_communicate_value, d8_prepared_value, d8_datecompl_value]);

	const renderData = useReactive({
		// activeKey: ['1_info', '45'],
		activeKey: ['45'],
		D45done: false,
		D6done: false,
		D7done: false,
		D8done: false,
	});

	const onChange = (key) => {
		renderData.activeKey = key;
	};

	const header45Render = useMemoizedFn(() => {
		if (renderData.D45done) {
			return (
				<span className="tabheaderitem">
					D4. Define Root Cause(s)<br></br>D5. Permanent Corrective Action(s){' '}
					<CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return (
				<span className="tabheaderitem">
					D4. Define Root Cause(s)<br></br>D5. Permanent Corrective Action(s)
				</span>
			);
		}
	});

	const header6Render = useMemoizedFn(() => {
		if (renderData.D6done) {
			return (
				<span className="tabheaderitem">
					D6. Verification of Effectiveness{' '}
					<CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return (
				<span className="tabheaderitem">D6. Verification of Effectiveness</span>
			);
		}
	});

	const header7Render = useMemoizedFn(() => {
		if (renderData.D7done) {
			return (
				<span className="tabheaderitem">
					D7. Prevention{' '}
					<CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return <span className="tabheaderitem">D7. Prevention</span>;
		}
	});

	const header8Render = useMemoizedFn(() => {
		if (renderData.D8done) {
			return (
				<span className="tabheaderitem">
					D8. Communicate Results and Recognize Team{' '}
					<CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return (
				<span className="tabheaderitem">
					D8. Communicate Results and Recognize Team
				</span>
			);
		}
	});

	const expandIconRender = (isActive) => {
		if (isActive) {
			return <img className="accordion-icon expand" alt="" src={ICONdown} />;
		} else {
			return <img className="accordion-icon shrink" alt="" src={ICONup} />;
		}
	};

	const startDatePickerOnChange = (date) => {
		setStartDateData(date);
		setEndDateData(null);
	};

	const endDatePickerOnChange = (date) => {
		setEndDateData(date);
	};

	return (
		<div>
			<Collapse
				bordered={false}
				activeKey={renderData.activeKey}
				className="custom-collapse"
				expandIconPosition="end"
				ghost
				expandIcon={({ isActive }) => expandIconRender(isActive)}
				onChange={onChange}
			>
				{/* <Panel
					header="Info for Acknowledgement"
					key="1_info"
					className="custom-panel"
					forceRender
				>
					<D13Info form={form} />
				</Panel> */}
				<Panel
					header={header45Render()}
					key="45"
					className="custom-panel panel45"
					forceRender
				>
					<D4D5 form={form} />
				</Panel>
				<Panel
					header={header6Render()}
					key="6"
					className="custom-panel panel6"
					forceRender
				>
					<D6Verification form={form} />
				</Panel>
				<Panel
					header={header7Render()}
					key="7"
					className="custom-panel"
					forceRender
				>
					<D7Prevention form={form} />
				</Panel>
				<Panel
					header={header8Render()}
					key="8"
					className="custom-panel"
					forceRender
				>
					<D8Communicate form={form} />
				</Panel>
			</Collapse>
		</div>
	);
});

export default Tab2;
