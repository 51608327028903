import React, {
	useImperativeHandle,
	useCallback,
	useState,
	Fragment,
	useMemo,
} from 'react';
import './InputFileUpload.scss';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import {
	fileToBase64,
	downloadFileByBase64WithFileName,
} from '../../utils/util';
import { useTranslation } from 'react-i18next';
import { Progress, Modal } from 'antd';
import '../../../node_modules/antd/lib/progress/style/index.css';
import '../../../node_modules/antd/lib/modal/style/index.css';
import { useUpdateEffect, useReactive } from 'ahooks';
import ICONclose from '../../assets/icons/closeWhite.svg';
// import { chain } from 'mathjs';
import Decimal from 'decimal.js';

let allowFileType = [
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'image/jpeg',
	'image/png',
];

const InputFileUpload = React.forwardRef(({ fetchDetailData }, ref) => {
	const { t } = useTranslation();
	const [percent, setPercent] = useState(0);
	const [isProgressShow, setIsProgressShow] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);

	const fileData = useReactive({
		totalfilesize: 0,
	});

	useImperativeHandle(ref, () => ({
		itattachments: itattachments,
		clearAll: () => {
			setItattachments([]);
		},
	}));

	useUpdateEffect(() => {
		if (JSON.stringify(fetchDetailData) !== '{}') {
			if (
				fetchDetailData['itattachments'] &&
				fetchDetailData['itattachments'].length
			) {
				for (let i = 0; i < fetchDetailData['itattachments'].length; i++) {
					if (fetchDetailData['itattachments'][i].size) {
						fileData.totalfilesize = Decimal(fileData.totalfilesize)
							.add(Decimal(fetchDetailData['itattachments'][i].size))
							.toNumber();
					}
				}
				//
				setItattachments(fetchDetailData['itattachments']);
			} else {
				setItattachments([]);
			}
		} else {
			setItattachments([]);
		}
	}, [fetchDetailData]); // eslint-disable-line

	const [itattachments, setItattachments] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length === 0) {
				return;
			}
			console.log(acceptedFiles);
			let tempError1 = [...itattachments];
			//
			fileData.totalfilesize = Decimal(fileData.totalfilesize)
				.add(Decimal(acceptedFiles[0].size))
				.toNumber();
			//
			if (
				Decimal(fileData.totalfilesize)
					.div(Decimal(1024))
					.div(Decimal(1024))
					.toNumber() > 35
			) {
				setIsModalVisible(true);
				//
				fileData.totalfilesize = Decimal(fileData.totalfilesize)
					.sub(Decimal(acceptedFiles[0].size))
					.toNumber();
				//
				return;
			}
			const data = new FormData();
			for (let i = 0; i < acceptedFiles.length; i++) {
				let temp = acceptedFiles[i].name.split('.');
				let fileName = temp[temp.length - 1].toUpperCase();
				//
				let check1 = tempError1.find((item) => {
					return item.filename === acceptedFiles[i].name;
				});
				if (check1) {
					let tempError = [...itattachments];
					let obj = {
						content: '',
						filename: acceptedFiles[i].name,
						size: acceptedFiles[i].size,
						errorMessage: t('Detail.fileagain'),
					};
					tempError.push(obj);
					setItattachments(tempError);
					return;
				}
				if (fileName === 'MSG') {
					//
				} else if (fileName === 'JFIF') {
					let tempError = [...itattachments];
					let obj = {
						content: '',
						filename: acceptedFiles[i].name,
						size: acceptedFiles[i].size,
						errorMessage:
							'This file format is not recognized by SAP, please save the file as PDF before attaching.',
					};
					tempError.push(obj);
					setItattachments(tempError);
					return;
				} else {
					let check2 = allowFileType.find((item) => {
						return acceptedFiles[i].type.includes(item);
					});
					if (!check2) {
						let tempError = [...itattachments];
						let obj = {
							content: '',
							filename: acceptedFiles[i].name,
							size: acceptedFiles[i].size,
							errorMessage:
								'This file format is not recognized by SAP, please save the file as PDF before attaching.',
						};
						tempError.push(obj);
						setItattachments(tempError);
						return;
					}
				}
				if (acceptedFiles[i].size / 1024 / 1024 > 10) {
					let tempError = [...itattachments];
					let obj = {
						content: '',
						filename: acceptedFiles[i].name,
						size: acceptedFiles[i].size,
						errorMessage: 'Attached file too big. Max. file size: 10 MB.',
					};
					tempError.push(obj);
					setItattachments(tempError);
					return;
				}
				data.append('files', acceptedFiles[i]);
			}
			// toggleLoading();
			setIsProgressShow(true);
			//
			let baseURL = '';
			if (window.location.origin.includes('localhost')) {
				baseURL = 'https://dev.ncm.basf.com';
			} else if (window.location.origin.includes('dev')) {
				baseURL = 'https://dev.ncm.basf.com';
			} else if (window.location.origin.includes('qual')) {
				baseURL = 'https://qual.ncm.basf.com';
			} else {
				baseURL = 'https://ncm.basf.com';
			}

			axios
				.post(`${baseURL}/securityScan/api/v1/scan/upload`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${localStorage.getItem('NCM_Token')}`,
					},
					transformRequest: [
						function (data) {
							return data;
						},
					],
					onUploadProgress: (progressEvent) => {
						let complete =
							((progressEvent.loaded / progressEvent.total) * 100) | 0;
						setPercent(Math.round(complete * 0.9));
					},
				})
				.then(async (response) => {
					setPercent(100);
					setTimeout(() => {
						setIsProgressShow(false);
					}, 1000);
					if (response.data.data && response.data.data.length) {
						let find = response.data.data.find((item) => {
							return item.detected === true;
						});
						if (find) {
							let tempError = [...itattachments];
							let obj = {
								content: '',
								filename: find.fileName,
								size: find.size,
								errorMessage:
									'The file could not be uploaded because it contains a virus.',
							};
							tempError.push(obj);
							setItattachments(tempError);
							return;
						} else {
							let tempBase64 = [...itattachments];
							for (let i = 0; i < acceptedFiles.length; i++) {
								let newFileName = '';
								//
								if (acceptedFiles[i].name.includes('‘')) {
									newFileName = acceptedFiles[i].name.replace('‘', '');
								}
								if (acceptedFiles[i].name.includes("'")) {
									newFileName = acceptedFiles[i].name.replace("'", '');
								}
								//
								let base64 = await fileToBase64(acceptedFiles[i]);
								let obj = {
									content: base64,
									filename: newFileName ? newFileName : acceptedFiles[i].name,
									size: acceptedFiles[i].size,
									errorMessage: '',
								};
								tempBase64.push(obj);
							}
							setItattachments(tempBase64);
						}
					}
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[itattachments]
	);

	const {
		// eslint-disable-next-line no-unused-vars
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		noKeyboard: true,
		onDrop,
		maxFiles: 1,
	});

	const removeFile = (e, index) => {
		e.stopPropagation();
		let tempBase64 = [...itattachments];
		tempBase64.splice(index, 1);
		setItattachments(tempBase64);
		//
		fileData.totalfilesize = 0;
		for (let i = 0; i < tempBase64.length; i++) {
			fileData.totalfilesize = Decimal(fileData.totalfilesize)
				.add(Decimal(tempBase64[i].size))
				.toNumber();
		}
	};

	const acceptStyle = {
		backgroundColor: '#D2ECF6',
	};

	const rejectStyle = {
		backgroundColor: '#f0f0f0',
	};

	const convertMB = (limit) => {
		if (limit) {
			limit = Number.parseFloat(limit);
			let size = '';
			if (limit < 0.1 * 1024) {
				size = limit.toFixed(2) + ' B';
			} else if (limit < 0.1 * 1024 * 1024) {
				size = (limit / 1024).toFixed(2) + ' KB';
			} else if (limit < 0.1 * 1024 * 1024 * 1024) {
				size = (limit / (1024 * 1024)).toFixed(2) + ' MB';
			} else {
				size = (limit / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
			}
			let sizeStr = size + '';
			let index = sizeStr.indexOf('.');
			let dou = sizeStr.substr(index + 1, 2);
			if (dou === '00') {
				return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
			}
			return size;
		}
	};

	const style = useMemo(
		() => ({
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragAccept, acceptStyle, isDragReject, rejectStyle]
	);

	const fileType = (name, error) => {
		let temp = name.split('.');
		let file = temp[temp.length - 1].toUpperCase();
		if (file.includes('DOC') || file.includes('DOCX')) {
			return `ext docx upload ${error}`;
		} else if (file.includes('PDF')) {
			return `ext pdf upload  ${error}`;
		} else if (file.includes('JPG') || file.includes('JPEG')) {
			return `ext jpg upload  ${error}`;
		} else if (file.includes('PNG')) {
			return `ext png upload  ${error}`;
		} else if (file.includes('XLS')) {
			return `ext xlsx upload  ${error}`;
		} else if (file.includes('MSG')) {
			return `ext msg upload  ${error}`;
		} else if (file.includes('PPT')) {
			return `ext ppt upload  ${error}`;
		} else if (file.includes('TXT')) {
			return `ext txt upload  ${error}`;
		} else if (file.includes('JFIF')) {
			return `ext jfif upload  ${error}`;
		} else {
			return `ext docx upload ${error}`;
		}
	};

	const files2Upload = itattachments.map((file, index) => {
		return (
			<li
				key={index}
				onClick={() =>
					downloadFileByBase64WithFileName(file.content, file.filename)
				}
			>
				{file.errorMessage && file.errorMessage !== '' ? (
					<span className={fileType(file.filename, 'error')}></span>
				) : (
					<span className={fileType(file.filename)}></span>
				)}

				<div className="details">
					<span className="name">{file.filename}</span>
					<span className="size">{convertMB(file.size)}</span>
					<button
						className="btnCancelFile"
						onClick={(e) => removeFile(e, index)}
					/>
				</div>
				{file.errorMessage && file.errorMessage !== '' ? (
					<div className="fileerrorMessage">{file.errorMessage}</div>
				) : (
					<Fragment />
				)}
			</li>
		);
	});

	return (
		<div className="boxUpload" ref={ref}>
			<Modal
				open={isModalVisible}
				className="cancelModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">Note:</div>
					<div className="content">{t('Overview.Pleasenotethat')}</div>
					<div className="buttongroup">
						<button
							className="btnCancel"
							onClick={() => setIsModalVisible(false)}
						>
							OK
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<div className="boxBrowse" {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<p>
					{t('Detail.Drag1')}
					<span className="alt">{t('Detail.Drag2')}</span>
					{t('Detail.Drag3')}
				</p>
			</div>
			{/* <div className="boxBrowse">
				<a href="/">Browse</a> your files here.
			</div> */}
			<span className="infoUpload">
				{t('Detail.FileType2')} <br />
				{t('Detail.MaxFile')}
			</span>

			<ul
				className={
					itattachments.length >= 1 ? 'lstFilesfile' : 'lstFilesfile empty'
				}
			>
				{files2Upload}
			</ul>
			{isProgressShow ? (
				<div className="progressbar">
					<Progress percent={percent} />
				</div>
			) : (
				<Fragment />
			)}
			<span className="errInput">Error</span>
		</div>
	);
});

export default InputFileUpload;
