import React, { Fragment, memo, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn, useMount, useUpdateEffect } from 'ahooks';
import { Input, Form, Row, Col, DatePicker, Select } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import '../../../../node_modules/antd/lib/select/style/index.css';
import _ from 'lodash';
import { onBlurValidateDirtyword, onSubmitRequired } from './patternRex';

const D5 = React.forwardRef(
	({ d5Id, d5item, shopFields, remove, children, d4index, d5index }, ref) => {
		// eslint-disable-next-line no-unused-vars
		const { t } = useTranslation();
		// const nameValue = Form.useWatch('d4d5', form);

		// const shopList = useMemo(() => {
		// 	const key = `store${storeId}`;
		// 	return storeIdToshop[key];
		// }, [storeId]);

		useMount(() => {
			// form.setFieldsValue({
			// 	d4d5: [
			// 		{
			// 			define: '',
			// 			rac: null,
			// 			rcaother: '',
			// 		},
			// 	],
			// 	d5_dca: [
			// 		{
			// 			permanent: '',
			// 			responsible: '',
			// 			datedue: null,
			// 			datecompl: null,
			// 		},
			// 	],
			// });
		});

		// useUpdateEffect(() => {
		// 	console.log(nameValue);
		// }, [nameValue]);

		// const addForm1 = (add) => {
		// 	let d5form = form.getFieldValue('d5_dca');
		// 	let d5_dca = _.cloneDeep(d5form);
		// 	let obj = {
		// 		permanent: '',
		// 		responsible: '',
		// 		datedue: null,
		// 		datecompl: null,
		// 	};
		// 	d5_dca.push(obj);
		// 	form.setFieldsValue({
		// 		d5_dca: d5_dca,
		// 	});
		// 	//
		// 	add();
		// };

		// const removeForm1 = (remove, name, index) => {
		// 	let d5form = form.getFieldValue('d5_dca');
		// 	let d5_dca = _.cloneDeep(d5form);
		// 	d5_dca.splice(index, 1);
		// 	form.setFieldsValue({
		// 		d5_dca: d5_dca,
		// 	});
		// 	//
		// 	remove(name);
		// };

		// const addForm2 = (add) => {
		// 	let d4form = form.getFieldValue('d4_rc');
		// 	let d4_rc = _.cloneDeep(d4form);
		// 	let obj = {
		// 		define: '',
		// 		rac: null,
		// 		rcaother: '',
		// 	};
		// 	d4_rc.push(obj);
		// 	form.setFieldsValue({
		// 		d4_rc: d4_rc,
		// 	});
		// 	//
		// 	add();
		// };

		// const removeForm2 = (remove, name, index) => {
		// 	let d4form = form.getFieldValue('d4_rc');
		// 	let d4_rc = _.cloneDeep(d4form);
		// 	d4_rc.splice(index, 1);
		// 	form.setFieldsValue({
		// 		d4_rc: d4_rc,
		// 	});
		// 	//
		// 	remove(name);
		// };

		// const renderOther = (name, index) => {
		// 	if (
		// 		nameValue &&
		// 		nameValue[index] &&
		// 		nameValue[index].rac &&
		// 		nameValue[index].rac === 'other'
		// 	) {
		// 		return (
		// 			<Col xs={24}>
		// 				<Form.Item label="" name={[name, 'rcaother']}>
		// 					<Input placeholder="Other" value={nameValue[index].rac} />
		// 				</Form.Item>
		// 			</Col>
		// 		);
		// 	} else {
		// 		return (
		// 			<Col xs={24}>
		// 				<Form.Item label="">&nbsp;</Form.Item>
		// 			</Col>
		// 		);
		// 	}
		// };

		return (
			<div className="formadditem">
				<span className="antd_form_title">
					Permanent Corrective Action {d4index + 1}-{d5index + 1}
				</span>
				<Row>
					{/* <Col xs={24}>
						<Form.Item
							label=""
							name={[d5item.name, 'd5_main_action']}
							className="antd_label"
							rules={[
								{
									required: true,
									message: '',
								},
							]}
						>
							<Select
								placeholder="Select main action"
								className="antd_select d4d5select"
								options={[
									{
										value: '1',
										label: 'Select main action1',
									},
									{
										value: '2',
										label: 'Select main action2',
									},
								]}
							/>
						</Form.Item>
					</Col>
					<Col xs={24}>
						<Form.Item
							label=""
							name={[d5item.name, 'd5_detail_action']}
							className="antd_label"
							rules={[
								{
									required: true,
									message: '',
								},
							]}
						>
							<Select
								placeholder="Select detail action"
								className="antd_select d4d5select"
								options={[
									{
										value: '1',
										label: 'Select detail action1',
									},
									{
										value: '2',
										label: 'Select detail action2',
									},
								]}
							/>
						</Form.Item>
					</Col> */}
					<Col span={24}>
						<Form.Item
							label=""
							name={[d5item.name, 'permanent']}
							validateTrigger={['onSubmit', 'onBlur']}
							rules={[onSubmitRequired, onBlurValidateDirtyword]}
						>
							<Input.TextArea
								autoSize={{
									minRows: 4,
									maxRows: 4,
								}}
								placeholder="Permanent Corrective Actions(s)"
								className="antd_input"
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label=""
							name={[d5item.name, 'responsible']}
							validateTrigger={['onSubmit', 'onBlur']}
							rules={[onSubmitRequired, onBlurValidateDirtyword]}
						>
							<Input placeholder="Responsible" className="antd_input" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label=""
							name={[d5item.name, 'datedue']}
							rules={[
								{
									required: true,
									message: '',
								},
							]}
						>
							<DatePicker
								style={{ width: '100%' }}
								suffixIcon={<></>}
								allowClear={false}
								placeholder="Date Due"
								format="YYYY-MM-DD"
								showToday={false}
								className="antd_date"
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label=""
							name={[d5item.name, 'datecompl']}
							rules={[
								{
									required: true,
									message: '',
								},
							]}
						>
							<DatePicker
								style={{ width: '100%' }}
								suffixIcon={<></>}
								allowClear={false}
								placeholder="Date Completed"
								format="YYYY-MM-DD"
								showToday={false}
								className="antd_date"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>{children}</Col>
				</Row>
			</div>
		);
	}
);

export default memo(D5);
