import React, { useImperativeHandle, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ICONdeputy from '../../../assets/icons/deputy.svg';
import ICONdownload from '../../../assets/icons/attachment-vertical-black.svg';
import Confirmletter from '../../../assets/images/confirmletter.png';
import ICONclose from '../../../assets/icons/closeWhite.svg';
import { useMemoizedFn } from 'ahooks';
import axios from '../../../axios-custom';
import store from '../../../state/store';
import { downloadFileByBase64 } from '../../../utils/util';
import { Modal } from 'antd';
import '../../../../node_modules/antd/lib/modal/style/index.css';
import { useHistory } from 'react-router-dom';
import { useReactive, useMount } from 'ahooks';
import ICONinfo from '../../../assets/icons/info-i.svg';
import { notification, Button } from 'antd';
const { useModel } = store;

const UnreadLetter = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);

	const [letterfiledet, setLetterfiledet] = useState('');
	const [letterfilename, setLetterfilename] = useState('');

	const [attachmentData, setAttachmentData] = useState([]);

	const history = useHistory();

	const ncData = useReactive({
		ncNumber: '',
		report: '',
		targetSystem: '',
		reportId: '',
	});

	// useMount(() => {
	// 	notification.open({
	// 		message: 'Locked',
	// 		description: descriptionHtml(),
	// 		icon: iconError(),
	// 		duration: 0,
	// 	});
	// });

	// const iconError = () => {
	// 	return (
	// 		<>
	// 			<img className="ICONinfo" alt="Error" src={ICONinfo} />
	// 		</>
	// 	);
	// };

	// const btn = (
	// 	<Button type="primary" size="small">
	// 		{t('Detail.Saveasdraft')}
	// 	</Button>
	// );

	// const descriptionHtml = () => {
	// 	return (
	// 		<Fragment>
	// 			<span>Locked</span>
	// 			{/* <br />
	// 			<button
	// 				className={`btn-back-to-start ${localStorage.getItem('NCM_lang')}`}
	// 				style={{ marginLeft: 0 }}
	// 				onClick={() => confirmReceipt()}
	// 			>
	// 				<span>{t('Detail.TryAgain')}</span>
	// 			</button> */}
	// 		</Fragment>
	// 	);
	// };

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const downLetter = () => {
		downloadFileByBase64(letterfiledet, letterfilename);
		setIsModalVisible2(false);
	};

	const handleCancel2 = () => {
		setIsModalVisible2(false);
	};

	useImperativeHandle(ref, () => ({
		//
	}));

	const confirmReceipt = useMemoizedFn(async (event, item) => {
		event.stopPropagation();
		//
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		//
		toggleLoading();
		let obj = {
			imemailresponsible: user.email,
			imqmnum: item.qmnum,
			imreport: item.report,
			loggedinId: loggedinId,
			responsible: item.receiveremail,
			targetSystem:
				item.targetSystem.toUpperCase() === 'COBALT' || !item.targetSystem
					? 'COBALT'
					: 'EC',
			reportId: item.reportid,
		};
		//
		const response = await axios.post(
			'/feedbackServices/portalSap/postletterfeedback',
			obj
		);
		toggleLoading();
		let notificationObj = {
			type: 'success',
			title: t('Overview.lettersubmit1'),
			description: t('Overview.lettersubmit2'),
			duration: 5,
		};
		toastMessageShow(notificationObj);
		localStorage.setItem('NCM_Overviewletter', 'show');
		history.push('/overview');
	});

	// const reference = useMemoizedFn((obj) => {
	// 	return <Fragment />;
	// 	// if (obj.report === 'YNCM_SUPPLIER_FEEDBACK') {
	// 	// 	return <Fragment>{obj.po}</Fragment>;
	// 	// } else if (obj.report === 'YNCM_HAULIER_FEEDBACK') {
	// 	// 	if (obj.shipment !== '' && obj.salesorder !== '' && obj.delivery !== '') {
	// 	// 		return <Fragment>{obj.shipment}</Fragment>;
	// 	// 	} else if (
	// 	// 		obj.shipment === '' &&
	// 	// 		obj.salesorder !== '' &&
	// 	// 		obj.delivery !== ''
	// 	// 	) {
	// 	// 		return <Fragment>{obj.salesorder}</Fragment>;
	// 	// 	} else if (
	// 	// 		obj.shipment === '' &&
	// 	// 		obj.salesorder === '' &&
	// 	// 		obj.delivery !== ''
	// 	// 	) {
	// 	// 		return <Fragment>{obj.delivery}</Fragment>;
	// 	// 	}
	// 	// }
	// });

	const downloadFile = useMemoizedFn((obj, e) => {
		console.log(obj);
		// console.log(e.target);
		if (
			e.target.nodeName === 'IMG' ||
			e.target.className === 'downloadDetail'
		) {
			//
			ncData.ncNumber = obj.qmnum;
			ncData.report = obj.report;
			ncData.targetSystem = obj.targetSystem;
			ncData.reportId = obj.reportid;
			//
			setAttachmentData(obj.attachmentList.item);
			setIsModalVisible(true);
		} else {
			setIsModalVisible2(true);
			if (obj.attachments && JSON.stringify(obj.attachments) !== '{}') {
				setLetterfiledet(obj.attachments.filedet);
				setLetterfilename(obj.attachments.filename);
			} else {
				setLetterfiledet('');
				setLetterfilename('');
			}
		}
	});

	const convertMB = (limit) => {
		var size = '';
		if (limit < 0.1 * 1024) {
			size = limit.toFixed(2) + ' B';
		} else if (limit < 0.1 * 1024 * 1024) {
			size = (limit / 1024).toFixed(2) + ' KB';
		} else if (limit < 0.1 * 1024 * 1024 * 1024) {
			size = (limit / (1024 * 1024)).toFixed(2) + ' MB';
		} else {
			size = (limit / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
		}

		var sizeStr = size + '';
		var index = sizeStr.indexOf('.');
		var dou = sizeStr.substr(index + 1, 2);
		if (dou === '00') {
			return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
		}
		return size;
	};

	const fileType = (name) => {
		let temp = name.split('.');
		let file = temp[temp.length - 1].toUpperCase();
		if (file.includes('DOC') || file.includes('DOCX')) {
			return `itemimage docx`;
		} else if (file.includes('PDF')) {
			return `itemimage pdf `;
		} else if (file.includes('JPG') || file.includes('JPEG')) {
			return `itemimage jpg `;
		} else if (file.includes('PNG')) {
			return `itemimage png `;
		} else if (file.includes('XLS')) {
			return `itemimage xlsx `;
		} else if (file.includes('MSG')) {
			return `itemimage msg `;
		} else if (file.includes('PPT')) {
			return `itemimage ppt `;
		} else if (file.includes('TXT')) {
			return `itemimage txt `;
		} else if (file.includes('MP4')) {
			return `itemimage mp4 `;
		} else {
			return `itemimage docx`;
		}
	};

	const downloadAtachFile = async (filename) => {
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		let obj = {
			ncNumber: ncData.ncNumber,
			filename: filename,
			imemail: user.email,
			reportId: ncData.reportId,
			targetSystem:
				ncData.targetSystem.toUpperCase() === 'COBALT' ? 'COBALT' : 'EC',
		};
		toggleLoading();
		let temp = await axios.post(
			'/feedbackServices/portalSap/downloadAttachment',
			obj
		);
		toggleLoading();
		if (temp.data.byteToString && temp.data.filename) {
			downloadFileByBase64(temp.data.byteToString, temp.data.filename);
		}
	};

	return (
		<Fragment>
			<Modal
				open={isModalVisible}
				className="attachModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">Attachment list</div>
					<div className="contentwrap">
						{attachmentData.map((file, index) => {
							return (
								<div
									className="itemwrap"
									key={index}
									onClick={() => downloadAtachFile(file.filename)}
								>
									<span className={fileType(file.filename)}></span>
									<span className="itemtitlewrap">
										<span className="itemfilename">{file.filename}</span>
										<span className="itemfilesize">
											{convertMB(file.filesize)}
										</span>
									</span>
								</div>
							);
						})}
					</div>
					<div className="buttongroup">
						<button className="btnCancel" onClick={handleCancel}>
							{t('Detail.Close')}
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={handleCancel}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<Modal
				visible={isModalVisible2}
				className="attachModal"
				footer={null}
				closable={false}
			>
				<div className="contentinto">
					<div className="title">{t('Overview.Donotforget')}</div>
					<div className="contentwrap">
						<img
							style={{ width: '60%', margin: '20px 0' }}
							src={Confirmletter}
							alt=""
						/>
					</div>
					<div className="buttongroup">
						<button
							className="btnLogin"
							onClick={downLetter}
							style={{ marginLeft: '0' }}
						>
							{t('Detail.Continue')}
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={handleCancel2}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<ul ref={ref} className={props.tabs ? 'lstLanding' : 'lstLanding active'}>
				{props.letters.map((item, index) => {
					return (
						<li
							className="overdue listcard"
							key={index}
							onClick={(e) => downloadFile(item, e)}
						>
							<span className="ncnumber">
								<span>{item.qmnum}</span>
								{/* {item.nctype && item.nctype.toUpperCase() !== 'Y1' ? (
									<Fragment>
										<span> - </span>
										<span>{item.nctype}</span>
									</Fragment>
								) : (
									<Fragment />
								)} */}
							</span>
							{/* <span className="reference">{reference(item)}</span> */}
							<span className="reference">{item.shipment}</span>
							<p className="title">{item.mreason}</p>
							<div className="customercomplaint">
								{item.nctype && item.nctype.toUpperCase() === 'Y1' ? (
									<span>Customer Complaint</span>
								) : (
									<span>&nbsp;</span>
								)}
							</div>
							<div className="boxConfirmation">
								<button
									className="cbxConfirm"
									onClick={(e) => confirmReceipt(e, item)}
								></button>
								<span className="lblPassive">
									{t('Overview.ConfirmReceipt')}
								</span>
								<span className="lblActive">
									{t('Overview.ReceiptConfirmed')}
								</span>
							</div>
							<div
								className={
									item.report.toUpperCase().includes('CARRIER')
										? 'boxTags draft'
										: 'boxTags'
								}
							>
								<span className="long">
									{item.report.toUpperCase().includes('CONFORMANCE') ? (
										<Fragment>{t('Overview.NoticeofNon')}</Fragment>
									) : (
										<Fragment>{t('Overview.LettertoCarrier')}</Fragment>
									)}
								</span>
								{item.icon === true ? (
									<Fragment>
										<div className="deputyDetail">
											<img
												alt="Deputy"
												className={
													item.report.toUpperCase().includes('CONFORMANCE')
														? ''
														: 'draft'
												}
												src={ICONdeputy}
											/>
											<p>
												{t('Overview.until1')}
												{item.todate}
												{t('Overview.until2')}
											</p>
										</div>
										{item.attachmentList &&
										item.attachmentList.item &&
										item.attachmentList.item.length !== 0 ? (
											<div className="downloadDetail">
												<img alt="Deputy" src={ICONdownload} />
											</div>
										) : (
											<Fragment />
										)}
									</Fragment>
								) : (
									<Fragment>
										{item.attachmentList &&
										item.attachmentList.item &&
										item.attachmentList.item.length !== 0 ? (
											<div className="downloadDetail">
												<img alt="Deputy" src={ICONdownload} />
											</div>
										) : (
											<Fragment />
										)}
									</Fragment>
								)}
							</div>
						</li>
					);
				})}
			</ul>
			{/* <div className="tiptext">
				<span style={{ fontWeight: '700' }}>{t('Overview.Rtiptext1')}</span>
				{t('Overview.Rtiptext2')}
			</div> */}
		</Fragment>
	);
});

export default UnreadLetter;
