import React, { useImperativeHandle } from 'react';
import '../index.scss';
import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import { Collapse, Form } from 'antd';
import '../../../../node_modules/antd/lib/collapse/style/index.css';
import D1team from './D1team';
import D2Describe from './D2Describe';
import D3Containment from './D3Containment';
import { useMount, useMemoizedFn, useUpdateEffect, useReactive } from 'ahooks';
import _ from 'lodash';
import { patternName, patternTel, patternEmail } from './patternRex';
import { CheckCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;

const Tab1 = React.forwardRef(({ form }, ref) => {
	const d1_leadername_value = Form.useWatch('d1_leadername', form);
	const d1_leaderdeptrole_value = Form.useWatch('d1_leaderdeptrole', form);
	const d1_leaderemail_value = Form.useWatch('d1_leaderemail', form);
	const d1_members_value = Form.useWatch('d1_members', form);
	const d2_problem_value = Form.useWatch('d2_problem', form);
	const d3_ca_value = Form.useWatch('d3_ca', form);
	const d3_ca_responsible_value = Form.useWatch('d3_ca_responsible', form);
	const d3_ca_datedue = Form.useWatch('d3_ca_datedue', form);
	const d3_ia_value = Form.useWatch('d3_ia', form);
	const d3_ia_responsible_value = Form.useWatch('d3_ia_responsible', form);
	const d3_ia_datedue = Form.useWatch('d3_ia_datedue', form);

	useMount(() => {
		//
	});

	useImperativeHandle(ref, () => ({
		D1error: () => {
			renderData.activeKey.push('1');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
		D2error: () => {
			renderData.activeKey.push('2');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
		D3error: () => {
			renderData.activeKey.push('3');
			renderData.activeKey = _.uniq(renderData.activeKey);
			// submitData.D2error = true;
		},
	}));

	const renderData = useReactive({
		activeKey: ['1'],
		D1done: false,
		D2done: false,
		D3done: false,
	});

	// const submitData = useReactive({
	// 	D2error: false,
	// });

	useUpdateEffect(() => {
		if (d2_problem_value) {
			// submitData.D2error = false;
			renderData.D2done = true;
		} else {
			renderData.D2done = false;
		}
	}, [d2_problem_value]);

	useUpdateEffect(() => {
		if (!patternName(d1_leadername_value)) {
			renderData.D1done = false;
			return;
		}
		if (!patternEmail(d1_leaderemail_value)) {
			renderData.D1done = false;
			return;
		}
		//
		if (
			d1_leadername_value &&
			d1_leaderdeptrole_value &&
			d1_leaderemail_value
		) {
			let temp = [];
			for (let i = 0; i < d1_members_value.length; i++) {
				let obj = {};
				obj.name = d1_members_value[i].name;
				obj.deptrole = d1_members_value[i].deptrole;
				obj.email = d1_members_value[i].email;
				temp.push(obj);
			}
			for (let i = 0; i < temp.length; i++) {
				//
				if (!patternName(temp[i].name)) {
					renderData.D1done = false;
					return;
				}
				if (!patternEmail(temp[i].email)) {
					renderData.D1done = false;
					return;
				}
				//
				let flag = _.values(temp[i]).some((v) => v === '' || !v);
				if (!flag) {
					renderData.D1done = true;
				} else {
					renderData.D1done = false;
				}
			}
		} else {
			renderData.D1done = false;
		}
	}, [
		d1_leadername_value,
		d1_leaderdeptrole_value,
		d1_leaderemail_value,
		d1_members_value,
	]);

	useUpdateEffect(() => {
		if (!d3_ia_value && !d3_ia_responsible_value && !d3_ia_datedue) {
			if (d3_ca_value && d3_ca_responsible_value && d3_ca_datedue) {
				// submitData.D2error = false;
				renderData.D3done = true;
			} else {
				renderData.D3done = false;
			}
		} else if (d3_ia_value && d3_ia_responsible_value && d3_ia_datedue) {
			if (d3_ca_value && d3_ca_responsible_value && d3_ca_datedue) {
				// submitData.D2error = false;
				renderData.D3done = true;
			} else {
				renderData.D3done = false;
			}
		} else {
			renderData.D3done = false;
		}
	}, [
		d3_ca_value,
		d3_ca_responsible_value,
		d3_ca_datedue,
		d3_ia_value,
		d3_ia_responsible_value,
		d3_ia_datedue,
	]);

	const onChange = (key) => {
		renderData.activeKey = key;
	};

	const expandIconRender = (isActive) => {
		if (isActive) {
			return <img className="accordion-icon expand" alt="" src={ICONdown} />;
		} else {
			return <img className="accordion-icon shrink" alt="" src={ICONup} />;
		}
	};

	const header2Render = useMemoizedFn(() => {
		// if (submitData.D2error) {
		// 	return (
		// 		<span className="tabheaderitem">
		// 			D2. Describe the Problem <Tag color="error">error</Tag>
		// 		</span>
		// 	);
		// } else {
		// 	if (renderData.D2done) {
		// 		return (
		// 			<span className="tabheaderitem">
		// 				D2. Describe the Problem <Tag color="success">done</Tag>
		// 			</span>
		// 		);
		// 	} else {
		// 		return <span className="tabheaderitem">D2. Describe the Problem</span>;
		// 	}
		// }
		if (renderData.D2done) {
			return (
				<span className="tabheaderitem">
					D2. Describe the Problem{' '}
					<CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return <span className="tabheaderitem">D2. Describe the Problem</span>;
		}
	});

	const header3Render = useMemoizedFn(() => {
		if (renderData.D3done) {
			return (
				<span className="tabheaderitem">
					D3. Containment Action(s){' '}
					<CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return <span className="tabheaderitem">D3. Containment Action(s)</span>;
		}
	});

	const header1Render = useMemoizedFn(() => {
		if (renderData.D1done) {
			return (
				<span className="tabheaderitem">
					D1. Team <CheckCircleFilled style={{ color: 'rgb(6, 174, 86)' }} />
				</span>
			);
		} else {
			return <span className="tabheaderitem">D1. Team</span>;
		}
	});

	return (
		<div>
			<Collapse
				bordered={false}
				activeKey={renderData.activeKey}
				className="custom-collapse"
				expandIconPosition="end"
				ghost
				expandIcon={({ isActive }) => expandIconRender(isActive)}
				onChange={onChange}
			>
				<Panel
					header={header1Render()}
					key="1"
					className="custom-panel"
					forceRender
				>
					<D1team form={form} />
				</Panel>
				<Panel
					header={header2Render()}
					key="2"
					className="custom-panel"
					forceRender
				>
					<D2Describe form={form} />
				</Panel>
				<Panel
					header={header3Render()}
					key="3"
					className="custom-panel"
					forceRender
				>
					<D3Containment form={form} />
				</Panel>
			</Collapse>
		</div>
	);
});

export default Tab1;
