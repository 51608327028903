import React, { Fragment, memo, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn, useReactive, useUpdateEffect } from 'ahooks';
import { Input, Form } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';

const D2Describe = React.forwardRef(({ form }, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	useImperativeHandle(ref, () => ({
		xxx: () => {
			// xxx
		},
	}));

	const data = useReactive({
		images: [],
	});

	return (
		<div>
			<Form.Item className="antd_label" label="" name="d2_sapproblem">
				<Input.TextArea
					className="antd_input"
					autoSize={{
						minRows: 4,
						maxRows: 4,
					}}
					placeholder="Problem from SAP"
					readOnly
				/>
			</Form.Item>
			<Form.Item className="antd_label" label="" name="d2_problem">
				<Input.TextArea
					className="antd_input"
					autoSize={{
						minRows: 4,
						maxRows: 4,
					}}
					placeholder="Describe the Problem"
				/>
			</Form.Item>
		</div>
	);
});

export default memo(D2Describe);
