import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn } from 'ahooks';
import { DatePicker, Input, Form, Row, Col } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import { onSubmitRequired, onBlurValidateDirtyword } from './patternRex';

const D8Communicate = React.forwardRef((_, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	const [startDateData, setStartDateData] = useState(null);
	const [endDateData, setEndDateData] = useState(null);
	const [startDateData2, setStartDateData2] = useState(null);
	const [endDateData2, setEndDateData2] = useState(null);

	const startDatePickerOnChange = (date) => {
		setStartDateData(date);
		setEndDateData(null);
	};

	const endDatePickerOnChange = (date) => {
		setEndDateData(date);
	};

	const startDatePickerOnChange2 = (date) => {
		setStartDateData2(date);
		setEndDateData2(null);
	};

	const endDatePickerOnChange2 = (date) => {
		setEndDateData2(date);
	};

	return (
		<div ref={ref}>
			<Row>
				<Col xs={24}>
					<Form.Item
						label=""
						name="d8_communicate"
						className="antd_label"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input
							className="antd_input"
							placeholder="Communicate Results and Recognize Team"
						/>
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						label=""
						name="d8_prepared"
						className="antd_label"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder="Prepared By" className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						label=""
						name="d8_datecompl"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder="Date Completed"
							format="YYYY-MM-DD"
							value={startDateData}
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
});

export default memo(D8Communicate);
