import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn, useMount } from 'ahooks';
import { DatePicker, Input, Form, Row, Col, Radio } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import '../../../../node_modules/antd/lib/radio/style/index.css';
import {
	onSubmitRequired,
	onBlurValidateDirtyword,
	onBlurEmpty,
} from './patternRex';

const D7Prevention = React.forwardRef(({ form }, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	const nameValue1 = Form.useWatch('d7_1', form);
	const nameValue2 = Form.useWatch('d7_2', form);
	const nameValue3 = Form.useWatch('d7_3', form);
	const nameValue4 = Form.useWatch('d7_4', form);
	const nameValue5 = Form.useWatch('d7_5', form);
	const nameValue6 = Form.useWatch('d7_6', form);
	const nameValue7 = Form.useWatch('d7_7', form);
	const nameValue8 = Form.useWatch('d7_8', form);
	const nameValue9 = Form.useWatch('d7_9', form);
	const nameValue10 = Form.useWatch('d7_10', form);

	const [startDateData, setStartDateData] = useState(null);
	const [endDateData, setEndDateData] = useState(null);
	const [startDateData2, setStartDateData2] = useState(null);
	const [endDateData2, setEndDateData2] = useState(null);

	useMount(() => {
		form.setFieldsValue({
			d7_choice: '',
		});
	});

	const startDatePickerOnChange = (date) => {
		setStartDateData(date);
		setEndDateData(null);
	};

	const endDatePickerOnChange = (date) => {
		setEndDateData(date);
	};

	const startDatePickerOnChange2 = (date) => {
		setStartDateData2(date);
		setEndDateData2(null);
	};

	const endDatePickerOnChange2 = (date) => {
		setEndDateData2(date);
	};

	const [componentDisabled, setComponentDisabled] = useState('');
	const onChange = (e) => {
		setComponentDisabled(e.target.value);
	};

	return (
		<div ref={ref}>
			<Row>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d7_prevention"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder="Prevention" className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d7_responsible"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder="Responsible" className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						className="antd_label"
						label=""
						name="d7_datedue"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder="Date Due"
							format="YYYY-MM-DD"
							onChange={(date) => startDatePickerOnChange(date)}
							value={startDateData}
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item
						label=""
						name="d7_datecompl"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder="Date Completed"
							format="YYYY-MM-DD"
							onChange={(date) => startDatePickerOnChange(date)}
							value={startDateData}
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						colon={false}
						label="Other Areas Affected"
						name="d7_choice"
						extra={
							componentDisabled === 'yes'
								? 'If yes, list where C/A can be implemented'
								: ''
						}
						className="antd_label"
						rules={[
							{
								required: true,
								message: 'Please select one',
							},
						]}
					>
						<Radio.Group onChange={onChange}>
							<Radio value="yes"> Yes </Radio>
							<Radio value="no"> No </Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				{(() => {
					if (componentDisabled === 'no') {
						return (
							<Col xs={24}>
								<Form.Item
									label=""
									name="d7_13"
									labelCol={{
										span: 6,
									}}
									validateTrigger={['onSubmit', 'onBlur']}
									rules={[onSubmitRequired, onBlurValidateDirtyword]}
								>
									<Input placeholder="Please Explain" className="antd_input" />
								</Form.Item>
							</Col>
						);
					} else if (componentDisabled === 'yes') {
						return (
							<>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Process FMEA"
										name="d7_1"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Process FMEA" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Form"
										name="d7_2"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Form" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Product FMEA"
										name="d7_3"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Product FMEA" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Specification"
										name="d7_4"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Specification" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Control Plan"
										name="d7_5"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Control Plan" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="PPAP"
										name="d7_6"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="PPAP" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Inspection Plan"
										name="d7_7"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder="Inspection Plan"
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Job Instruction"
										name="d7_8"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder="Job Instruction"
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Procedure"
										name="d7_9"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Procedure" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="Other"
										name="d7_10"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="Other" className="antd_input" />
									</Form.Item>
								</Col>
							</>
						);
					} else {
						return <></>;
					}
				})()}
			</Row>
		</div>
	);
});

export default memo(D7Prevention);
