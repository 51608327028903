import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn, useMount, useUpdateEffect } from 'ahooks';
import { Input, Form, Row, Col, Select } from 'antd';
import D5 from './D5';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import '../../../../node_modules/antd/lib/select/style/index.css';
import _ from 'lodash';
import { onBlurValidateDirtyword, onSubmitRequired } from './patternRex';

const D4D5 = React.forwardRef(({ form }, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	const [d5Id, setBizBrandId] = useState(0);
	const nameValue = Form.useWatch('d4d5_rac', form);

	useMount(() => {
		form.setFieldsValue({
			d4d5: [
				{
					define: '',
					d5: [
						{
							d5_main_action: '',
							d5_detail_action: '',
							permanent: '',
							responsible: '',
							datedue: null,
							datecompl: null,
						},
					],
				},
			],
		});
	});

	// useUpdateEffect(() => {
	// 	console.log(nameValue);
	// }, [nameValue]);

	const addForm1 = (add) => {
		let d4d5form = form.getFieldValue('d4d5');
		let d4d5 = _.cloneDeep(d4d5form);
		let obj = {
			define: '',
			d5: [
				{
					d5_main_action: '',
					d5_detail_action: '',
					permanent: '',
					responsible: '',
					datedue: null,
					datecompl: null,
				},
			],
		};
		d4d5.push(obj);
		form.setFieldsValue({
			d4d5: d4d5,
		});
	};

	const addForm2 = (add, index) => {
		let d4d5form = form.getFieldValue('d4d5');
		let d4d5 = _.cloneDeep(d4d5form);
		let obj = {
			d5_main_action: '',
			d5_detail_action: '',
			permanent: '',
			responsible: '',
			datedue: null,
			datecompl: null,
		};
		d4d5[index].d5.push(obj);
		form.setFieldsValue({
			d4d5: d4d5,
		});
	};

	const removeForm1 = (remove, name, index) => {
		let d5form = form.getFieldValue('d5_dca');
		let d5_dca = _.cloneDeep(d5form);
		d5_dca.splice(index, 1);
		form.setFieldsValue({
			d5_dca: d5_dca,
		});
		//
		remove(name);
	};

	const removeForm2 = (remove, name, index) => {
		let d4form = form.getFieldValue('d4_rc');
		let d4_rc = _.cloneDeep(d4form);
		d4_rc.splice(index, 1);
		form.setFieldsValue({
			d4_rc: d4_rc,
		});
		//
		remove(name);
	};

	return (
		<div ref={ref}>
			<Row>
				<Col span={11}>
					<Form.Item
						label=""
						name="d4d5_rac"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<Select
							placeholder="Select RCA Method/Tool used"
							className="antd_select d4d5select"
							options={[
								{
									value: 'Ishikawa',
									label: 'Ishikawa',
								},
								{
									value: 'Fishbone Diagram',
									label: 'Fishbone Diagram',
								},
								{
									value: '5 Whys',
									label: '5 Whys',
								},
								{
									value: 'Fault Tree Analysis',
									label: 'Fault Tree Analysis',
								},
								{
									value: 'other',
									label: 'Other',
								},
							]}
						/>
					</Form.Item>
				</Col>
				<Col span={11} offset={2}>
					{nameValue === 'other' ? (
						<Form.Item
							label=""
							name="d4d5_rcaother"
							className="antd_label"
							validateTrigger={['onSubmit', 'onBlur']}
							rules={[
								{
									validator: (_, value) => {
										if (!value && nameValue === 'other') {
											return Promise.reject();
										} else {
											return Promise.resolve();
										}
									},
									validateTrigger: 'onSubmit',
								},
								onBlurValidateDirtyword,
							]}
						>
							<Input className="antd_input" placeholder="Other" value="" />
						</Form.Item>
					) : (
						<Form.Item
							label=""
							name="d4d5_rcaother"
							className="antd_label"
							style={{ display: 'none' }}
							validateTrigger={['onSubmit', 'onBlur']}
							rules={[
								{
									validator: (_, value) => {
										if (!value && nameValue === 'other') {
											return Promise.reject();
										} else {
											return Promise.resolve();
										}
									},
									validateTrigger: 'onSubmit',
								},
								onBlurValidateDirtyword,
							]}
						>
							<Input className="antd_input" placeholder="Other" value="" />
						</Form.Item>
					)}
				</Col>
			</Row>
			<Form.List name="d4d5">
				{(d4d5Fields, { add, remove }) => (
					<>
						{d4d5Fields.map((item, index) => (
							<div key={index}>
								<Row>
									<Col span={11}>
										<div className="formadditem">
											<span className="antd_form_title">
												Define Root Cause {index + 1}
											</span>
											<Row>
												{/* <Col xs={24}>
													<Form.Item
														label=""
														name={[item.name, 'd4_main_cause']}
														className="antd_label"
														rules={[
															{
																required: true,
																message: '',
															},
														]}
													>
														<Select
															placeholder="Select main root cause"
															className="antd_select d4d5select"
															options={[
																{
																	value: '1',
																	label: 'Select main root cause1',
																},
																{
																	value: '2',
																	label: 'Select main root cause2',
																},
															]}
														/>
													</Form.Item>
												</Col>
												<Col xs={24}>
													<Form.Item
														label=""
														name={[item.name, 'd4_detail_cause']}
														className="antd_label"
														rules={[
															{
																required: true,
																message: '',
															},
														]}
													>
														<Select
															placeholder="Select detail root cause"
															className="antd_select d4d5select"
															options={[
																{
																	value: '1',
																	label: 'Select detail root cause1',
																},
																{
																	value: '2',
																	label: 'Select detail root cause2',
																},
															]}
														/>
													</Form.Item>
												</Col> */}
												<Col xs={24}>
													<Form.Item
														label=""
														name={[item.name, 'define']}
														className="antd_label"
														validateTrigger={['onSubmit', 'onBlur']}
														rules={[onSubmitRequired, onBlurValidateDirtyword]}
													>
														<Input.TextArea
															placeholder="Define Root Cause"
															autoSize={{
																minRows: 4,
																maxRows: 4,
															}}
															className="antd_input"
														/>
													</Form.Item>
												</Col>
											</Row>
										</div>
									</Col>
									<Col span={11} offset={2}>
										<Form.List {...item} name={[item.name, 'd5']}>
											{(d5Fields, { add, remove }) => (
												<div>
													{d5Fields.map((d5item, d5index) => (
														<D5
															d5Id={d5Id}
															key={d5item.key}
															d5item={d5item}
															d5index={d5index}
															d4index={index}
														>
															{d5Fields.length !== 1 && (
																<div
																	className="btnAddArea"
																	onClick={() => remove(d5item.name)}
																>
																	- Remove Corrective Action
																</div>
															)}
														</D5>
													))}
													<div
														className="btnAddArea"
														onClick={() => addForm2(add, index)}
													>
														+ Add Corrective Action
													</div>
												</div>
											)}
										</Form.List>
									</Col>
								</Row>
								<Row>
									<Col span={11}>
										{d4d5Fields.length !== 1 && (
											<div
												className="btnAddArea"
												onClick={() => remove(item.name)}
											>
												- Remove Root Cause
											</div>
										)}
									</Col>
									<Col span={11} offset={2}>
										&nbsp;
									</Col>
								</Row>
							</div>
						))}
						<Row>
							<Col span={24}>
								<div className="btnAddArea" onClick={() => addForm1(add)}>
									+ Add Root Cause
								</div>
							</Col>
						</Row>
					</>
				)}
			</Form.List>
		</div>
	);
});

export default memo(D4D5);
