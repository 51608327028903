import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn, useMount, useReactive } from 'ahooks';
import { Input, Form, Row, Col, Select, Tooltip } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import '../../../../node_modules/antd/lib/tooltip/style/index.css';
import './col.css';
import { countryData } from '../../../locales/countryphone';
import ICONinfo from '../../../assets/icons/info-red.svg';
import _ from 'lodash';
import {
	patterns,
	onSubmitRequired,
	onBlurValidateDirtyword,
	onBlurValidateName,
	onBlurValidateTel,
	onBlurValidateEmail,
} from './patternRex';

const D1team = React.forwardRef(({ form }, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	const leaderCountryValue = Form.useWatch('d1_leadercountry', form);
	const d1_membersValue = Form.useWatch('d1_members', form);
	const d1_leaderemailValue = Form.useWatch('d1_leaderemail', form);
	const d1_leadernameValue = Form.useWatch('d1_leadername', form);

	const data = useReactive({
		countryDropData: [],
	});

	useMount(() => {
		form.setFieldsValue({
			d1_members: [
				{
					name: '',
					deptrole: '',
					country: undefined,
					tel: '',
					email: '',
				},
			],
		});
	});

	useMount(() => {
		countryData.sort((a, b) => {
			if (a.countryName < b.countryName) {
				return -1;
			}
			if (a.countryName > b.countryName) {
				return 1;
			}
			return 0;
		});
		let country = [];
		for (let i = 0; i < countryData.length; i++) {
			let obj = {};
			obj.value = `${countryData[i].countryName} +${countryData[i].phoneCode}`;
			obj.label = `${countryData[i].countryName} +${countryData[i].phoneCode}`;
			obj.text = `+${countryData[i].phoneCode}`;
			country.push(obj);
		}
		data.countryDropData = _.uniqBy(country, 'value');
	});

	const onChange = (value) => {
		if (!value) {
			form.setFieldsValue({
				d1_leadertel: '',
			});
		}
	};

	const onChange1 = (...value) => {
		if (!value[0]) {
			let d1form = form.getFieldValue('d1_members');
			let d1_members = _.cloneDeep(d1form);
			d1_members[value[2]].tel = '';
			form.setFieldsValue({
				d1_members: d1_members,
			});
		}
	};

	const addForm = () => {
		let d1form = form.getFieldValue('d1_members');
		let d1_members = _.cloneDeep(d1form);
		let obj = {
			name: '',
			deptrole: '',
			country: undefined,
			tel: '',
			email: '',
		};
		d1_members.push(obj);
		form.setFieldsValue({
			d1_members: d1_members,
		});
	};

	const nameOnBlur = (e) => {
		if (e.target.value) {
			if (!patterns.name.test(e.target.value)) {
				e.target.nextSibling.classList.add('errorwrapshow');
				e.target.nextSibling.classList.remove('errorwraphide');
			} else {
				e.target.nextSibling.classList.add('errorwraphide');
				e.target.nextSibling.classList.remove('errorwrapshow');
			}
		} else {
			e.target.nextSibling.classList.add('errorwraphide');
			e.target.nextSibling.classList.remove('errorwrapshow');
		}
	};

	const emailOnBlur = (e) => {
		if (e.target.value) {
			if (!patterns.email.test(e.target.value)) {
				e.target.nextSibling.classList.add('errorwrapshow');
				e.target.nextSibling.classList.remove('errorwraphide');
			} else {
				e.target.nextSibling.classList.add('errorwraphide');
				e.target.nextSibling.classList.remove('errorwrapshow');
			}
		} else {
			e.target.nextSibling.classList.add('errorwraphide');
			e.target.nextSibling.classList.remove('errorwrapshow');
		}
	};

	return (
		<div ref={ref}>
			<span className="antd_form_title">Leader</span>
			<Row>
				<Col xs={24} md={11}>
					<Form.Item className="antd_label" label="">
						<Form.Item
							noStyle
							name="d1_leadername"
							validateTrigger={['onSubmit', 'onBlur']}
							rules={[onSubmitRequired, onBlurValidateName]}
						>
							<Input
								className="antd_input"
								placeholder="Name"
								onBlur={(e) => nameOnBlur(e)}
							/>
						</Form.Item>
						<div className="errorwrap errorwraphide">
							{t('Error.validname')}
							<Tooltip
								mouseLeaveDelay={0}
								color="#c71526"
								placement="right"
								title={t('Register.ErrorMesage4')}
							>
								<img className="erroricon" alt="iconinfo" src={ICONinfo} />
							</Tooltip>
						</div>
					</Form.Item>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item
						className="antd_label"
						label=""
						name="d1_leaderdeptrole"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input className="antd_input" placeholder="Dept/Role" />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Row>
						<Col span={12}>
							<Form.Item className="antd_label" name="d1_leadercountry">
								<Select
									className="antd_select"
									placeholder="Select country"
									style={{
										width: '100%',
									}}
									allowClear
									showSearch
									optionFilterProp="children"
									onChange={onChange}
									filterOption={(input, option) =>
										(option?.label ?? '').includes(input)
									}
									options={data.countryDropData}
									optionLabelProp="text"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								className="antd_label"
								name="d1_leadertel"
								validateTrigger={['onSubmit', 'onBlur']}
								rules={[
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value && getFieldValue('d1_leadercountry')) {
												return Promise.reject();
											} else {
												return Promise.resolve();
											}
										},
										validateTrigger: 'onSubmit',
									}),
									onBlurValidateTel,
								]}
							>
								<Input
									className="antd_input"
									style={{
										width: 'calc(100% - 5px)',
										marginLeft: '6px',
									}}
									placeholder="Contact number (Optional)"
									disabled={!leaderCountryValue}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item className="antd_label" label="">
						<Form.Item
							noStyle
							name="d1_leaderemail"
							validateTrigger={['onSubmit', 'onBlur']}
							rules={[onSubmitRequired, onBlurValidateEmail]}
						>
							<Input
								className="antd_input"
								placeholder="Email"
								onBlur={(e) => emailOnBlur(e)}
							/>
						</Form.Item>
						<div className="errorwrap errorwraphide">
							{t('Error.validemail')}
							<Tooltip
								mouseLeaveDelay={0}
								color="#c71526"
								placement="right"
								title={t('Register.ErrorMesage5')}
							>
								<img className="erroricon" alt="iconinfo" src={ICONinfo} />
							</Tooltip>
						</div>
					</Form.Item>
				</Col>
			</Row>
			<Form.List name="d1_members">
				{(fields, { add, remove }) => (
					<>
						{fields.map(({ key, name, ...restField }, index) => (
							<div className="formadditem" key={key}>
								<span className="antd_form_title">
									Additional Members {index + 1}
								</span>
								<Row>
									<Col xs={24} md={11}>
										<Form.Item className="antd_label" label="">
											<Form.Item
												noStyle
												{...restField}
												name={[name, 'name']}
												validateTrigger={['onSubmit', 'onBlur']}
												rules={[onSubmitRequired, onBlurValidateName]}
											>
												<Input
													className="antd_input"
													placeholder="Name"
													onBlur={(e) => nameOnBlur(e)}
												/>
											</Form.Item>
											<div className="errorwrap errorwraphide">
												{t('Error.validname')}
												<Tooltip
													mouseLeaveDelay={0}
													color="#c71526"
													placement="right"
													title={t('Register.ErrorMesage4')}
												>
													<img
														className="erroricon"
														alt="iconinfo"
														src={ICONinfo}
													/>
												</Tooltip>
											</div>
										</Form.Item>
									</Col>
									<Col xs={24} md={{ span: 11, offset: 2 }}>
										<Form.Item
											className="antd_label"
											{...restField}
											name={[name, 'deptrole']}
											validateTrigger={['onSubmit', 'onBlur']}
											rules={[onSubmitRequired, onBlurValidateDirtyword]}
										>
											<Input className="antd_input" placeholder="Dept/Role" />
										</Form.Item>
									</Col>
									<Col xs={24} md={11}>
										<Row>
											<Col span={12}>
												<Form.Item
													className="antd_label"
													name={[name, 'country']}
												>
													<Select
														placeholder="Select country"
														className="antd_select"
														style={{
															width: '100%',
														}}
														allowClear
														showSearch
														optionFilterProp="children"
														onChange={(...arg) => {
															onChange1(...arg, index);
														}}
														filterOption={(input, option) =>
															(option?.label ?? '').includes(input)
														}
														options={data.countryDropData}
														optionLabelProp="text"
													/>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item
													className="antd_label"
													name={[name, 'tel']}
													validateTrigger={['onSubmit', 'onBlur']}
													rules={[
														{
															validator: (_, value) => {
																if (!value && d1_membersValue[index]?.country) {
																	return Promise.reject();
																} else {
																	return Promise.resolve();
																}
															},
															validateTrigger: 'onSubmit',
														},
														onBlurValidateTel,
													]}
												>
													<Input
														className="antd_input"
														style={{
															width: 'calc(100% - 5px)',
															marginLeft: '6px',
														}}
														placeholder="Contact number (Optional)"
														disabled={!d1_membersValue[index]?.country}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col xs={24} md={{ span: 11, offset: 2 }}>
										<Form.Item className="antd_label" label="">
											<Form.Item
												noStyle
												name={[name, 'email']}
												validateTrigger={['onSubmit', 'onBlur']}
												rules={[onSubmitRequired, onBlurValidateEmail]}
											>
												<Input
													className="antd_input"
													placeholder="Email"
													onBlur={(e) => emailOnBlur(e)}
												/>
											</Form.Item>
											<div className="errorwrap errorwraphide">
												{t('Error.validemail')}
												<Tooltip
													mouseLeaveDelay={0}
													color="#c71526"
													placement="right"
													title={t('Register.ErrorMesage5')}
												>
													<img
														className="erroricon"
														alt="iconinfo"
														src={ICONinfo}
													/>
												</Tooltip>
											</div>
										</Form.Item>
									</Col>
								</Row>
								{fields.length > 1 && key > 0 ? (
									<div className="btnAddArea">
										<span onClick={() => remove(name)}>- Remove Member</span>
									</div>
								) : (
									<Fragment />
								)}
							</div>
						))}
						{fields.length < 7 ? (
							<div className="btnAddArea">
								<span onClick={() => addForm(add)}>+ Add Member</span>
							</div>
						) : (
							<Fragment />
						)}
					</>
				)}
			</Form.List>
		</div>
	);
});

export default memo(D1team);
