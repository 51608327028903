import React, { useState, useEffect, useRef, Fragment } from 'react';
import './index.scss';
import LeftDetail from './detail';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import { Input, Modal, Form } from 'antd';
import ICONinfo from '../../assets/icons/info-i.svg';
import '../../../node_modules/antd/lib/input/style/index.css';
import '../../../node_modules/antd/lib/modal/style/index.css';
import '../../../node_modules/antd/lib/form/style/index.css';
import Tab1 from './components/Tab1';
import Tab2 from './components/Tab2';
import { getQueryVariable } from '../../utils/util';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useMount, useReactive } from 'ahooks';
import { useTranslation } from 'react-i18next';
import Print from './components/Print';

function DReport({ bgHandler }) {
	const { t } = useTranslation();

	const [isModalVisible, setIsModalVisible] = useState(true);
	const [expandedSupplier, setExpandedSupplier] = useState(false);

	const location = useLocation();

	const tab1Ref = useRef();
	const tab2Ref = useRef();
	const printRef = useRef(null);

	const renderData = useReactive({
		tabShow: 1,
		topFormError: false,
		form1Error: false,
		form2Error: false,
	});

	const [formtop] = Form.useForm();
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();

	useEffect(() => {
		bgHandler(false, false, IMAGlandingBig, false);
	}, [bgHandler]);

	useMount(() => {
		const { search } = location;
		if (search) {
			let code = getQueryVariable(search, 'code');
			const myHeaders = new Headers();
			myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
			const urlencoded = new URLSearchParams();
			//
			let redirectUrl = '';
			let client_id = '';
			let client_secret = '';
			let federation = '';
			if (window.location.origin.includes('localhost')) {
				redirectUrl = 'https://localhost:8100';
				client_id = '355006f9-603c-4642-a38a-6460e0737b57';
				client_secret =
					'oAILlxjLNHyplhvYY9G7RojuEAVmkHpgXYvBEUjKFJSJyjJWace7BFfaxjtk2udUDqN7Qrx8aNW-N0_h51HgQg';
				federation = 'https://federation-qa.basf.com/nidp/oauth/nam/token';
			} else if (window.location.origin.includes('dev')) {
				redirectUrl = 'https://dev.ncm.basf.com';
				client_id = 'a9526525-7323-41db-8a3f-955090557519';
				client_secret =
					'oAILlxjLNHyplhvYY9G7RojuEAVmkHpgXYvBEUjKFJSJyjJWace7BFfaxjtk2udUDqN7Qrx8aNW-N0_h51HgQg';
				federation = 'https://federation.basf.com/nidp/oauth/nam/token';
			} else if (window.location.origin.includes('qual')) {
				redirectUrl = 'https://qual.ncm.basf.com';
				client_id = '355006f9-603c-4642-a38a-6460e0737b57';
				client_secret =
					'YV_a1PcT95SipTKKkzJj2f-k8Bu7KQNRIbwww3lOs8gQ96wcRVOxSHQM128L3kp4t8UMQwKofsBwZq0oPK7W_Q';
				federation = 'https://federation-qa.basf.com/nidp/oauth/nam/token';
			} else {
				redirectUrl = 'https://ncm.basf.com';
				client_id = 'a9526525-7323-41db-8a3f-955090557519';
				client_secret =
					'oAILlxjLNHyplhvYY9G7RojuEAVmkHpgXYvBEUjKFJSJyjJWace7BFfaxjtk2udUDqN7Qrx8aNW-N0_h51HgQg';
				federation = 'https://federation.basf.com/nidp/oauth/nam/token';
			}
			//
			urlencoded.append('client_id', client_id);
			urlencoded.append('client_secret', client_secret);
			urlencoded.append('code', code);
			urlencoded.append('grant_type', 'authorization_code');
			urlencoded.append('resourceServer', 'Unencrypted');
			urlencoded.append('redirect_uri', redirectUrl);

			const requestOptions = {
				method: 'POST',
				headers: myHeaders,
				body: urlencoded,
				redirect: 'follow',
			};
			checkUser(federation, requestOptions);
		}
	});

	const checkUser = async (federation, requestOptions) => {
		const temp = await fetch(federation, requestOptions);
		const userTemp = await temp.json();
		console.log(userTemp);
		if (temp.status === 400) {
			console.log(userTemp.error_description);
			// Federation Service Error
			return;
		}
		//
		let decode = {};
		try {
			decode = jwtDecode(userTemp.access_token);
		} catch (error) {
			//
			return;
		}
		console.log(decode);
		if (decode.mail === 'jonathan.poh@basf.com') {
			setIsModalVisible(false);
		}
		return;
	};

	const onSearch = (value) => {
		// goFed();
		if (value === '8dreport') {
			setIsModalVisible(false);
		}
	};

	const changeTab = (val) => {
		renderData.tabShow = val;
	};

	const draft = () => {
		// try {
		// 	const values = form1.validateFields();
		// 	console.log('Success:', values);
		// } catch (errorInfo) {
		// 	console.log('Failed:', errorInfo);
		// 	renderData.topFormError = true;
		// 	setTimeout(() => {
		// 		renderData.topFormError = false;
		// 	}, 5000);
		// }
	};

	const submit = async () => {
		if (renderData.tabShow === 1) {
			try {
				const result = await Promise.all([
					formtop.validateFields(),
					form1.validateFields(),
				]);
				console.log(result);
				console.log('验证成功');
			} catch (errorInfo) {
				console.log(errorInfo);
				scrollTop();
				renderData.topFormError = true;
				//
				for (let i = 0; i < errorInfo.errorFields.length; i++) {
					if (errorInfo.errorFields[i].name[0].includes('d1_')) {
						tab1Ref.current.D1error();
					}
					// if (errorInfo.errorFields[i].name[0].includes('d2_')) {
					// 	tab1Ref.current.D2error();
					// }
					if (errorInfo.errorFields[i].name[0].includes('d3_')) {
						tab1Ref.current.D3error();
					}
				}
				//
				setTimeout(() => {
					renderData.topFormError = false;
				}, 5000);
			}
		} else {
			try {
				const result = await Promise.all([
					formtop.validateFields(),
					form2.validateFields(),
				]);
				console.log(result);
				console.log('验证成功');
			} catch (errorInfo) {
				console.log(errorInfo);
				scrollTop();
				renderData.topFormError = true;
				//
				for (let i = 0; i < errorInfo.errorFields.length; i++) {
					if (errorInfo.errorFields[i].name[0].includes('d45_')) {
						tab2Ref.current.D45error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d6_')) {
						tab2Ref.current.D6error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d7_')) {
						tab2Ref.current.D7error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d8_')) {
						tab2Ref.current.D8error();
					}
				}
				//
				setTimeout(() => {
					renderData.topFormError = false;
				}, 5000);
			}
		}

		return;
		try {
			const values = await formtop.validateFields();
			console.log('Success1:', values);
			await aaa();
		} catch (err) {
			console.log('Failed:', err);
			renderData.topFormError = true;
			scrollTop();
			// setTimeout(() => {
			// 	document.querySelector('.ant-form-item-has-error').scrollIntoView({
			// 		block: 'start',
			// 		inline: 'nearest',
			// 		behavior: 'smooth',
			// 	});
			// }, 100);
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
		}

		return;

		try {
			const values = await formtop.validateFields();
			console.log('Success1:', values);
			throw 'myException';
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			renderData.topFormError = true;
			scrollTop();
			// setTimeout(() => {
			// 	document.querySelector('.ant-form-item-has-error').scrollIntoView({
			// 		block: 'start',
			// 		inline: 'nearest',
			// 		behavior: 'smooth',
			// 	});
			// }, 100);
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
			throw new Error(errorInfo);
		}
		try {
			const values = await form1.validateFields();
			console.log('Success2:', values);
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			console.log('Failed:', errorInfo.errorFields);
			renderData.form1Error = true;
			scrollTop();
			setTimeout(() => {
				renderData.form1Error = false;
			}, 5000);
			for (let i = 0; i < errorInfo.errorFields.length; i++) {
				if (errorInfo.errorFields[i].name[0].includes('d2_')) {
					tab1Ref.current.D2error();
				}
				if (errorInfo.errorFields[i].name[0].includes('d1_')) {
					tab1Ref.current.D1error();
				}
				if (errorInfo.errorFields[i].name[0].includes('d3_')) {
					tab1Ref.current.D3error();
				}
			}
		}
	};

	const aaa = async () => {
		try {
			const values = await form1.validateFields();
			console.log('Success2:', values);
		} catch (err) {
			console.error('inner', err);
		}
	};

	const scrollTop = (number = 0, time) => {
		if (!time) {
			document.body.scrollTop = document.documentElement.scrollTop = number;
			return number;
		}
		const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
		let spacingInex = time / spacingTime; // 计算循环的次数
		let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
		const everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
		const scrollTimer = setInterval(() => {
			if (spacingInex > 0) {
				spacingInex--;
				scrollTop((nowTop += everTop));
			} else {
				clearInterval(scrollTimer); // 清除计时器
			}
		}, spacingTime);
	};

	const print = () => {
		printRef.current.print();
	};

	return (
		<Fragment>
			<Print ref={printRef} />
			<section className="dreport print_hide">
				<Modal
					style={{
						maxWidth: '100vw',
						top: 0,
						paddingBottom: 0,
					}}
					className="passmodal"
					bodyStyle={{
						height: 'calc(100vh - 48px)',
					}}
					width="100vw"
					open={isModalVisible}
					footer={null}
					closable={false}
				>
					<div
						className="passwrap"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<div
							className="passwordwrap"
							style={{ width: '400px', marginTop: '20px' }}
						>
							<Input.Search
								placeholder="input password"
								allowClear
								enterButton="Submit"
								size="large"
								type="password"
								onSearch={onSearch}
							/>
						</div>
					</div>
				</Modal>
				<div className="supplier">
					<div className="boxHeadSupplier">
						<span>Date</span>
						<h3>09-08-2021</h3>
						<button
							className={
								expandedSupplier
									? 'btnToggleSupplier expanded'
									: 'btnToggleSupplier'
							}
							onClick={() => setExpandedSupplier(!expandedSupplier)}
						>
							<img className="expand" alt="Expand" src={ICONdown} />
							<img className="shrink" alt="Shrink" src={ICONup} />
							<span className="lessInfo">show less details</span>
							<span className="moreInfo">show more details</span>
						</button>
					</div>

					<LeftDetail expandedSupplier={expandedSupplier}></LeftDetail>
				</div>
				<div className="dfeedback">
					<span className="status open">
						<span>Open</span>
					</span>
					<Form.Provider>
						<div>
							{renderData.topFormError ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<div className="formtopwrap">
								<Form
									size="large"
									className="reportform"
									name="formtop"
									autoComplete="off"
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									form={formtop}
								>
									<span className="antd_form_title">Supplier Material No.</span>
									<Form.Item label="" name="SupplierMaterialNo">
										<Input
											className="antd_input"
											placeholder="Supplier Material No."
										/>
									</Form.Item>
									<span className="antd_form_title">
										Supplier Material Name
									</span>
									<Form.Item label="" name="Supplier Material Name">
										<Input
											className="antd_input"
											placeholder="Supplier Material Name"
										/>
									</Form.Item>
								</Form>
							</div>
							<div className="tabwrap">
								<span
									className={
										renderData.tabShow === 1 ? 'tabitem current' : 'tabitem'
									}
									onClick={() => changeTab(1)}
								>
									Acknowledgement
								</span>
								<span
									className={
										renderData.tabShow === 2 ? 'tabitem current' : 'tabitem'
									}
									onClick={() => changeTab(2)}
								>
									Investigation
								</span>
							</div>
							{renderData.form1Error ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<Form
								size="large"
								name="form1"
								className={
									renderData.tabShow === 1
										? 'reportform formshow'
										: 'reportform formhide'
								}
								autoComplete="off"
								form={form1}
							>
								<Tab1 form={form1} ref={tab1Ref} />
							</Form>
							{renderData.form2Error ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<Form
								size="large"
								name="form2"
								className={
									renderData.tabShow === 1
										? 'reportform formhide'
										: 'reportform formshow'
								}
								autoComplete="off"
								form={form2}
							>
								<Tab2 form={form2} ref={tab2Ref} />
							</Form>
						</div>
					</Form.Provider>
					<div className="boxActions">
						<button className="btnCancel">Cancel</button>
						<button className="btnPrint" onClick={print}>
							Print
						</button>
						{renderData.tabShow === 1 ? (
							<Fragment />
						) : (
							<button className="btnSave" onClick={() => draft()}>
								Save as draft
							</button>
						)}
						<button className="btnSend" onClick={() => submit()}>
							Submit
						</button>
					</div>
				</div>
			</section>
		</Fragment>
	);
}

export default DReport;
